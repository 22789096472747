import React from "react";
import Ofer from "assets/Leadership-Profile/Ofer-Tirosh.jpg";
import Maya from "assets/Leadership-Profile/Maya.jpg";
import Michal from "assets/Leadership-Profile/michal.jpg";
import Shashank from "assets/Leadership-Profile/shashank.jpg";
import William from "assets/Leadership-Profile/william.jpg";
import Liro from "assets/Leadership-Profile/Liro.jpg";
import Ortal from "assets/Leadership-Profile/Ortal.jpg";
import Jeff from "assets/Leadership-Profile/jeff.jpg";
import Jerica from "assets/Leadership-Profile/jerica.jpg";
import Mitesh from "assets/Leadership-Profile/mitesh.jpg";
import Rachelle from "assets/Leadership-Profile/rachelle.jpg";
import Linkedin from "assets/Leadership-Profile/linkedin.svg";
import { Link } from "gatsby";

const profileData = [
  {
    profile: Ofer,
    name: " Ofer Tirosh",
    role: "Chief Executive Officer",
    about: `Ofer founded Tomedes in 2007 to address gaps in the language industry. He drives daily efforts to grasp client needs and to enhance services for guaranteed customer satisfaction.`,
    url: "https://www.linkedin.com/in/ofertirosh/?originalSubdomain=il",
  },
  {
    profile: Maya,
    name: " Maya Ronen",
    role: "Chief Operating Officer",
    about: `Maya oversees daily business operations and ensures all departments and international teams run smoothly and efficiently. She promotes a culture committed to collaboration and customer success in their service.`,
    url: "https://www.linkedin.com/in/maya-margaliot-ronen-340773ab/",
  },
  {
    profile: William,
    name: " WILLIAM MAMANE",
    role: "CHIEF MARKETING OFFICER",
    about: `William leads Tomedes' global marketing efforts, including strategy, brand, content, demand generation, and product marketing. He ensures that Tomedes' marketing endeavors effectively promote its values and mission.`,
    url: "https://www.linkedin.com/in/william-levy-mamane-2611ba6a/",
  },
  {
    profile: Michal,
    name: " MICHAL BLUM",
    role: "CHIEF FINANCIAL OFFICER",
    about: `Michal manages the financial planning, budgeting, reporting, and monitoring of the fiscal health and performance of Tomedes, ensuring that global sales and business developments move toward customer satisfaction.`,
    url: "https://www.linkedin.com/in/michal-blum-6ab62a242/",
  },
  {
    profile: Shashank,
    name: " Shashank Jain",
    role: "HEAD OF TECHNOLOGY",
    about: `Shashank helms over Tomedes' innovations and development in technology, constantly finding new ways to improve internal and external processes to keep all systems streamlined for an immersive customer experience.`,
    url: "https://www.linkedin.com/in/shashankjain92/",
  },
  {
    profile: Liro,
    name: " Emilyn Sinamban",
    role: "Global Customer Success Manager",
    about: `Emilyn leads Tomedes' customer success team by establishing solid relationships with clients and maximizing customer loyalty by ensuring projects meet the client's requirements and specific needs.`,
    url: "https://www.linkedin.com/in/emilyn-sinamban-a8a70a163/",
  },
  {
    profile: Ortal,
    name: " ORTAL LASRY",
    role: "HEAD OF ISRAEL SALES",
    about: `Ortal heads the sales strategy that guarantees the satisfaction and loyalty of our Israel-based clients. She cultivates robust relationships with clients by adeptly understanding their needs and objectives.`,
    url: "https://www.linkedin.com/in/ortal-lasry-a0b55088/",
  },
  {
    profile: Jeff,
    name: " Jeff Patanindagat",
    role: "Operations Manager",
    about: `Jeffrey works closely with our vendors to ensure high-quality output in their service. He leads Tomedes' global labor management and operations, ensuring customers are satisfied after every project.`,
    url: "https://www.linkedin.com/in/jeffrey-patanindagat/",
  },
  {
    profile: Jerica,
    name: " JERICA FERNES",
    role: "Head of HUMAN Resources & Vendor Management",
    about: `Jerica manages international vendors and human resources through talent acquisition, strategic vendor relationships, and training programs that support the company's goals and customer-centric efforts.`,
    url: "https://www.linkedin.com/in/jerica-fernes-6883b289/",
  },
  // {
  //   profile: Mitesh,
  //   name: " Mitesh Thakur",
  //   role: "Tech Localization Manager",
  //   about: `Mitesh drives the optimization of technology solutions, enhancing the efficiency and quality of localization services. He ensures successful integration of technology in the localization process, delivering the best results to clients.`,
  //   url: "https://www.linkedin.com/in/mitesh-thakur/",
  // },
  {
    profile: Rachelle,
    name: " Rachelle Garcia",
    role: "Head of AI",
    about: `Rachelle oversees Tomedes AI efforts, diligently tracking cutting-edge AI innovations and their efficient integration into daily operations and all language services to elevate customer experience.`,
    url: "https://www.linkedin.com/in/rachelle-garcia-4564b3169/",
  },
];

const Profile = () => {
  return (
    <section
      className="my-[100px] max-w-7xl px-1 mx-auto  lg:place-content-between md:place-content-center  grid justify-items-center xl:gap-x-[100px] lg:gap-x-[80px] 
    md:gap-x-[20px] lg:gap-y-[100px]  gap-y-[20px] md:grid-cols-2  lg:grid-cols-3 "
    >
      {profileData.map((item) => (
        <div className="flex   flex-col max-w-[336px] rounded-lg shadow-lg overflow-hidden  ">
          <img src={item.profile} />
          <div className=" p-[18px] flex flex-col ">
            <span className="text-[#181D22] font-primary font-bold text-[24px] uppercase">
              {item.name}
            </span>
            <span className="text-[#001427] mt-2 text-base uppercase font-[500] h-[55px] font-primary  tracking-[0.9px]">
              {item.role}
            </span>
            <p className="text-[#000]  font-primary text-base  md:h-[190px]">
              {item.about}
            </p>
            <Link to={item.url} target="blank" className="md:mt-0 mt-[40px]">
              <img src={Linkedin} className="h-[50px] w-[50px] " />
            </Link>
          </div>
        </div>
      ))}
    </section>
  );
};

export default Profile;
